import React, { useState } from "react"
import { isValidIBAN } from "ibantools"
import styled from "styled-components"
import { BiReset } from "react-icons/bi"
import PropTypes from "prop-types"

const IbanChecker = ({
  buttonText,
  checkTitle,
  className,
  emptyIbanText,
  isInvalidText,
  isValidText,
  labelText,
  resetTitle,
  title,
  titleHtmlElement,
}) => {
  const titleElement = React.createElement(titleHtmlElement, null, title)
  const [iban, setIban] = useState("")
  const [lastResult, setLastResult] = useState(null)
  const handleOnChange = event => {
    setIban(event.target.value.toUpperCase())
  }
  const handleCheckIban = () => {
    if (iban.length === 0) {
      setLastResult(emptyIbanText)
    } else {
      setLastResult(isValidIBAN(iban) ? isValidText : isInvalidText)
    }
  }
  const handleReset = () => {
    setLastResult(null)
    setIban("")
  }
  return (
    <div className={className}>
      {titleElement}
      <div className="input-box">
        {labelText && <label>{labelText}</label>}
        <input value={iban} onChange={handleOnChange} />
        <button title={resetTitle} className="reset" onClick={handleReset}>
          <BiReset />
        </button>
      </div>
      <button title={checkTitle} className="check" onClick={handleCheckIban}>
        {buttonText}
      </button>
      {lastResult !== null && <div className="last-result">{lastResult}</div>}
    </div>
  )
}

IbanChecker.propTypes = {
  buttonText: PropTypes.string,
  checkTitle: PropTypes.string,
  className: PropTypes.string,
  emptyIbanText: PropTypes.string,
  isInvalidText: PropTypes.string,
  isValidText: PropTypes.string,
  labelText: PropTypes.string,
  resetTitle: PropTypes.string,
  title: PropTypes.string,
  titleHtmlElement: PropTypes.string,
}

IbanChecker.defaultProps = {
  checkTitle: "Click to check the IBAN",
  emptyIbanText: "You must enter an IBAN in order to check.",
  title: "Iban Checker",
  titleHtmlElement: "h4",
  buttonText: "Check IBAN",
  labelText: "Insert the IBAN you want to check.",
  isValidText: "The IBAN is VALID.",
  isInvalidText: "The IBAN is INVALID.",
  resetTitle: "Reset IBAN",
}

const StyledIbanChecker = styled(IbanChecker)`
  text-align: center;
  background: #eaeaea;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;

  .input-box {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  input {
    font-size: 0.9rem;
    border: 1px solid #efefef;
    margin-bottom: 2rem;
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 0.5rem;
    text-align: center;
  }

  .check {
    background-color: #0090c1;
    padding: 0.5rem 1rem;
    color: #fff;
    font-weight: 500;
    &:hover {
      background-color: #046e8f;
    }
  }

  .reset {
    position: absolute;
    right: 0;
    padding: 0.5rem;
    font-size: 2rem;
    color: #9a9a9a;
  }
  label {
    display: block;
    font-size: 1rem;
  }
  .last-result {
    padding: 1rem;
    font-weight: 500;
  }

  @media (min-width: 40rem) {
    width: auto;
    margin-left: auto;
    left: auto;
    .input-box {
      position: relative;
      width: 28rem;
      margin: 0 auto;
    }
  }
`

export { StyledIbanChecker }
export default IbanChecker
